import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
window.$ = window.jQuery = require("jquery");

export default {
    name: "PensumsPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PensumsPage",
            ss: ss,
            ajax: {
                "url": ss.indexMateria(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idEspecialidad = 0;
                }
            },
            columns: [
                { data: 'idMateria', name: 'idMateria', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Materia', name: 'Materia', title: 'Materia' },
                { data: 'Sigla', name: 'Sigla', title: 'Sigla' },
                { data: 'TipoMateria', name: 'TipoMateria', title: 'TipoMateria' },
                { data: 'tipo', name: 'tipo', title: 'tipo' },
                //{ data: 'Periodo', name: 'Periodo', title: 'Periodo' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            materia:{},
            accion:'',
            materiaStore:{},
            usuario:{},
            cargaHoraria:{},
            especialidad:{},
            especialidadsaga:{},
            competenciaProfesionalSelect:{},
            competenciaGeneral:{},
            competenciaArea:{},
            competenciaEspecifica:{},
            competenciaInstrumental:{},
            competenciasArea:[],
            especialidades:[],
            especialidadesSAGA:[],
            competenciasEspecificas:[],
            competenciasInstrumentales:[],
            competenciaInstrumentalCCP:[],
            periodos:[],
            nivelAcad: [
                { id: 1, value: 'Grado' },
                { id: 2, value: 'Tecnico' }
            ],
            nombreEspecialidad:'',
            isLoading: false,
            isLoadingFile: false,
            isPropuesta: false,
            errorBag: {},
            password: {},
            placeholderRep : '',
            selectedHitRep: 0,
        };
    },
    methods: {
        
        criterioSeleccion(item){
            this.competenciaProfesionalSelect = item;
            this.showCompetenciaEspecifica(item.id);
        },

        cancelStoreProfesional(){
            this.$refs['frm-Competencia-Profesional'].hide();
        },

        cancelStoreEspecifica(){
            this.$refs['frm-Competencia-Especifica'].hide();
        },

        storeMateria(item){
            if(item){
                this.materiaStore = item;
            }else{
                this.materiaStore={};
            }
            this.$refs['frm-materia'].show();
        },

        cancelStoreMateria(){
            this.$refs['frm-materia'].hide();
        },

        getUnidadEspecialidad() {
            this.ss.listEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.especialidades = response;
                    console.log(this.especialidades);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getPeriodosAcademicos(){
            //listPeriodosAcademicos
            this.ss.listPeriodosAcademicos(this.materiaStore.idNivelAcad).then(
                (result) => {
                    let response = result.data;
                    this.periodos = response.data.rows;
                    console.log(this.periodos);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        eliminarCompetenciaProfesionalPorArea(){
            this.$swal.fire({
                title: 'Eliminar Competencia Profesional por Área ?',
                text: "Esta accion es irreversible !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Eliminar !'
              }).then((result) => {
                if (result.isConfirmed) {

                    this.$swal.fire(
                        'Eliminado!',
                        'Competencia Profesiona Por Área Eliminado.',
                        'success'
                      )
                      //FALTA EL SERVIVIO PARA ELIMINAR EL CONTENIDO ANALITICO
                }
              })
            
        },

        showMateria(id){
            this.isLoading=true;
            this.ss.showMateria(id).then(
                (result) => {
                    let response = result.data;
                    this.materia = response.data;
                    console.log(this.materia);
                    this.isLoading=false;
                    this.$refs['view-materia'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        },



        showCompetenciaGeneral(id){
            this.isLoading = true;
            this.ss.showCompetenciaGeneral(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.competenciaGeneral = response.data[0];
                        console.log(this.competenciaGeneral);
                        this.especialidad = this.competenciaGeneral;
                        this.showCompetenciaArea(this.competenciaGeneral.id);
                        this.showCompetenciasInstrumentales(this.competenciaGeneral.id);
                    }
                    else{
                        this.competenciaGeneral={};
                        this.showEspecialidad(id); 
                    }
                    this.isLoading = false;
                    this.$refs['view-especialidad'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        },


        showCompetenciaArea(id){
            this.ss.showCompetenciaArea(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.competenciasArea = response.data;
                    console.log(this.competenciasArea);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        async storeMateriaCargaHoraria(item){
            await this.ss.storeMateriaCargaHoraria(item).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                }
            ).catch(error => {
                console.log(error);
            });
        },

    saveMateria(){
        this.isLoading=true;
        this.materiaStore.idEspecialidad = this.especialidadsaga.idEspecialidad ? this.especialidadsaga.idEspecialidad: this.usuario.idEspecialidad ;
        this.materiaStore.idTipoMateria = 1;
        this.materiaStore.tipo = 'Competencias 2024-2028';

         this.ss.storeMateria(this.materiaStore).then(
            (result) => {
                console.log(result);
                let response = result.data;
                console.log(response.data);
               /*  this.cargaHoraria = response.data.data;
                this.accion = response.acc;
                this.$bvToast.toast(
                    response.msg,
                    {
                        title: 'Correcto',
                        variant: 'success',
                        autoHideDelay: 5000
                    }
                ) */
                this.$refs['frm-materia'].hide();
                this.$refs['datatable-Materia'].reload();
                this.isLoading=false;

            })
            .catch((error) => {
                this.errorBag = error.response.data.errors;
                this.$bvToast.toast(
                    'Problema al Guardar el Registro, favor verificar los Datos',
                    {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000
                    }
                )
            });
       },

    async getEspecialidad2(){
        this.isLoading=true;
        if(this.especialidadsaga.idEspecialidad){
            let id = this.especialidadsaga.idEspecialidad;
            this.ajax.data = function(d){
                d.idEspecialidad = id;
            }
        }
        else{
            let id = this.usuario.idEspecialidad;
            this.ajax.data = function(d){
                d.idEspecialidad = id;
            }
        }
        await new Promise(resolve => setTimeout(resolve, 2000));

        this.$refs['datatable-Materia'].reloadAjax(this.ajax);
        this.isLoading=false;
    },

    getEspecialidades(){
        this.ss.listEspecialidad().then(
           (result) => {
               let response = result.data;
               console.log(response);
               this.especialidadesSAGA = response;
           }
       ).catch(error => {
           console.log(error);
       });
    },

    showReporte(){

        if(this.usuario.idEspecialidad){
            let idEspecialidad = this.usuario.idEspecialidad;
            let url = `${process.env.VUE_APP_MAIN_SERVICE}api/Materia/pdfMaterias?id=${idEspecialidad}`;
            console.log(url);
            window.open(url, '_blank');
            this.usuario.idEspecialidad=null;
            this.$refs['view-especialidad-select'].hide();
        }
        else{
            this.$refs['view-especialidad-select'].show();
        }
    },

        draw() {
            window.$('.btn-datatable-Materia').on('click', (evt) => {
                let idMateria = window.$(evt.target)[0].getAttribute('idMateria');
                if(idMateria){
                    this.showMateria(idMateria);
                }else{
                    this.$bvToast.toast(
                        'Ocurrio un problema inesperado, intente nuevamente.',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }
            });
        }
    },
    components: {
        dataTable,
        Loader,
        VueBootstrapTypeahead
    },
    watch: {
        selectedHitRep(value) {
            this.materiaStore.idEspecialidad = value.idEspecialidad;
        },
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.usuario = persona;
            console.log(this.usuario);
            this.getUnidadEspecialidad();
            this.getEspecialidad2();
            this.getEspecialidades();
        }
    }
};
